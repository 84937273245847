<template>
    <v-container fluid>
        <v-row justify="center">
            <customer-payment-receipt :saleId="this.$route.params.id"></customer-payment-receipt>
        </v-row>
    </v-container>
</template>

<script>
import saleInvoice from "../../components/customer-payment-receipt.component.vue";

export default {
    name: 'CustomerPaymentReceipt',
    data: () => ({
        company: {
            company_name: '',
            address: '',
            contact_us: '',
            email: '',
            type: ''
        },
    }),

    async created() {
        await this.$store.dispatch('companyProfile/getCompanyProfile');
        this.company.company_name = this.$store.getters['companyProfile/company'].company_name;
        this.company.address = this.$store.getters['companyProfile/company'].address;
        this.company.contact_us = this.$store.getters['companyProfile/company'].contact_us;
        this.company.email = this.$store.getters['companyProfile/company'].email;
        this.company.type = this.$store.getters['companyProfile/company'].type;
    },

    components: {
		"customer-payment-receipt": saleInvoice,
    },
}
</script>
